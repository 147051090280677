<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/BasicConfig/CertificateConfigList"
            >证书配置 /
          </router-link>
          <span class="crumbs_item crumbs_last"
            >{{ $route.query.operation == 1 ? "新增" : "编辑" }}证书配置</span
          >
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">证书模板：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="form.pictureName"
            readOnly
            @click="certificateShow = true"
            unselectable="on"
            placeholder="请选择证书模板"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">证书名称：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="form.certifName"
            placeholder="请输入证书名称"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">关联课程：</span>
        <div class="right_Div">
          <a-select
            style="width: 337px"
            placeholder="请选择关联课程"
            v-model="form.courseId"
            @change="selectCourse"
            showSearch
            :filter-option="filterOption"
            allowClear
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              v-for="item in courseList"
              :key="item.courseId"
              :value="item.courseId"
            >
              {{ item.courseName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">证书类型：</span>
        <div class="right_Div">
          <a-radio-group
            class="radioGroup"
            @change="onChangeCodeType"
            v-model="form.tempType"
          >
            <a-radio :value="1">中康协认证</a-radio>
            <!-- <a-radio :value="2" >学习证书</a-radio> -->
            <a-radio :value="5">CEU课程</a-radio>
            <a-radio :value="6">自研课程证书</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">编号类型：</span>
        <div class="right_Div">
          <a-radio-group class="radioGroup" v-model="form.codeType">
            <a-radio :value="1">学习证书（前缀+ YYYYMMDD +5位流水号）</a-radio>
            <a-radio :value="2">考试证书（前缀+ YYYYMM +5位流水号）</a-radio>
            <a-radio :value="3">考试证书（前缀+ YYYY- +4位流水号）</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner" v-show="form.tempType">
        <span class="all_left_name all_required">编号前缀：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="form.codePrefix"
            :placeholder="CodePlaceholder"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">发放方式：</span>
        <div class="right_Div">
          <a-radio-group class="radioGroup" v-model="form.generateType">
            <a-radio :value="1">系统发放</a-radio>
            <a-radio :value="2">人工发放</a-radio>
            <a-radio :value="3">多种方式均可生成</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">数据生成节点：</span>
        <div class="right_Div">
          <a-radio-group class="radioGroup" v-model="form.generateScope">
            <a-radio :value="2">学完课程后可获得证书</a-radio>
            <a-radio :value="1">考试状态为通过后获得证书</a-radio>
            <a-radio :value="4">获课成功后获得证书</a-radio>
            <!-- <a-radio :value="3" >按考试查询用户</a-radio> -->
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner" style="margin-bottom: 0" v-if="false">
        <span class="all_left_name all_required">证书编号：</span>
        <div class="right_Div">
          <a class="add" @click="ruleShow = true">新建编码</a>
          可设置固定字符、日期、序列号等组合证书编号规则
        </div>
      </div>
      <div class="L_R_inner" style="margin-top: 0" v-if="false">
        <span class="all_left_name"></span>
        <div class="right_Div">
          <a-table
            class="table-template"
            style="margin-top: 10px"
            :rowKey="(item) => item.id"
            :columns="columns"
            :data-source="tableData"
            bordered
          >
            <template slot="index" slot-scope="item, row, i">
              {{ (pageNumber - 1) * pageSize + i + 1 }}
            </template>

            <template slot="isEnable" slot-scope="item">
              {{ item == 1 ? "已启用" : "已停用" }}
            </template>

            <template slot="operation" slot-scope="item, row">
              <span class="blueText">
                <span @click="editFilling(row)">编辑</span> |
                <span @click="deleteData(row)">删除</span>
              </span>
            </template>
          </a-table>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">获取数据方式：</span>
        <div class="right_Div">
          <a-radio-group class="radioGroup" v-model="form.generateSource">
            <a-radio :value="1">确认信息数据</a-radio>
            <a-radio :value="2">用户信息数据</a-radio>
            <a-radio :value="3">全部(优先获取确认信息数据)</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="submit-btn">
        <a-button
          type="primary"
          class="btn"
          v-hasPermi="['certif:config:add']"
          @click="onSubmit"
          :loading="submitLoad"
          >确定</a-button
        >
        <a-button class="btn" @click="$router.go(-1)">返回</a-button>
      </div>
    </div>

    <!-- 证书模板选择 -->
    <a-modal v-model="certificateShow" width="530px" title="请选择证书模板">
      <div class="certificate_box">
        <a-radio-group class="radio" v-model="pictureId">
          <a-radio
            :value="item.pictureId"
            class="radioItem"
            v-for="item in pictureDropDownList"
            :key="item.pictureId"
          >
            <viewer>
              <img :src="item.pictureUrl" alt="" />
            </viewer>
          </a-radio>
        </a-radio-group>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="selectPicture()">确认</a-button>
        <a-button @click="certificateShow = false">取消</a-button>
      </template>
    </a-modal>
    <a-modal v-model="ruleShow" width="530px" title="请添加编码规则">
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">请选择字符类型：</span>
          <div class="right_Div">
            <a-select style="width: 280px" v-model="ruleCode">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option value="1">固定字符</a-select-option>
              <a-select-option value="2">日期</a-select-option>
              <a-select-option value="3">序列号</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="L_R_inner" v-show="ruleCode == 1">
          <span class="all_left_name">请填写字符内容：</span>
          <div class="right_Div">
            <a-input style="width: 280px" />
          </div>
        </div>
        <div class="L_R_inner" v-show="ruleCode == 2">
          <span class="all_left_name">请选择日期类型：</span>
          <div class="right_Div">
            <a-select style="width: 280px">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option value="1">YYYY</a-select-option>
              <a-select-option value="2">YYYY-MM</a-select-option>
              <a-select-option value="3">YYYY-MM-DD</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="L_R_inner" v-show="ruleCode == 3">
          <span class="all_left_name">请填写序列号位数：</span>
          <div class="right_Div">
            <div style="width: 280px">
              <a-input style="width: 50px" /> 位序列号
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary">确认</a-button>
        <a-button @click="ruleShow = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "规则类型",
    align: "center",
    width: "150px",
    dataIndex: "bannerTitle",
  },
  {
    title: "指定规则",
    align: "center",
    width: "150px",
    dataIndex: "bannerLink",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      tempId: 0,
      columns,
      tableData: [],
      CourseList: ["卡罗课程", "孤独症课程", "vb课程", "言语课程", "上岗课程"],
      pictureId: 0,
      ruleShow: false,
      submitLoad: false,
      certificateShow: false,
      ruleCode: "1",
      courseList: [], //课程列表
      pictureDropDownList: [], // 证书模板列表
      form: {
        pictureName: "", // 证书模板名称
        pictureId: "", // 证书模板id
        certifName: "", // 证书名称
        courseName: "", // 关联课程名称
        courseId: undefined, // 关联课程id
        generateType: "", // 发放方式 1.系统发放 2.手动发放, 3.系统发放同时支持人工补录
        generateScope: "", // 数据生成节点 1.考试通过 , 2.学完课程发放 3.考过试 4.购买过课程的
        tempType: "", // 证书类型 1.考试模板 2.课程模板  5.CEU证书  6、(自研)考试证书
        codeType: "", // 1.编号类型 学习证书（5位前缀+YYYYMMDD+5位流水号）   2.考试证书（5位前缀+YYYYMM+5位流水号）
        codePrefix: "", // 编号前缀

        generateSource: "", // 获取数据方式 1.确认考试数据,2.用户数据,3.两者都有，考试数据优先
      },
      CodePlaceholder: "", // 前缀提示
    };
  },
  // 事件处理器
  methods: {
    // 选择证书
    selectPicture() {
      if (!this.pictureId) {
        this.$message.error("请选择证书模板");
        return;
      }
      this.certificateShow = false;
      let obj = this.pictureDropDownList.filter((item) => {
        return item.pictureId == this.pictureId;
      })[0];
      this.form.pictureName = obj.pictureName;
      this.form.pictureId = obj.pictureId;
    },
    // 选择课程
    selectCourse(e) {
      if (!e) return;
      let obj = this.courseList.filter((item) => {
        return item.courseId == e;
      })[0];
      this.form.courseName = obj.courseName;
    },
    // 关联课程快捷搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 查询证书配置详情
    getDetail() {
      this.submitLoad = true;
      this.$ajax({
        url: "/hxclass-management/certiftemp/getTempConfigDetail",
        method: "get",
        params: {
          tempId: this.tempId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data;
          this.pictureId = res.data.pictureId;
        }
        this.submitLoad = false;
      });
    },
    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/select",
        method: "get",
        params: {
          scopeFlag: 1,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取证书列表
    getPictureDropDownList() {
      this.$ajax({
        url: "/hxclass-management/certiftemp/getPictureDropDownList",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.pictureDropDownList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 数据非空校验
    funCheck() {
      var check = false;
      if (!this.form.pictureId) {
        this.$message.error("请选择证书模板");
        check = true;
      } else if (!this.form.certifName) {
        this.$message.error("请填写证书名称");
        check = true;
      } else if (!this.form.courseId) {
        this.$message.error("请选择关联课程");
        check = true;
      } else if (!this.form.generateType) {
        this.$message.error("请选择发放方式");
        check = true;
      } else if (!this.form.generateScope) {
        this.$message.error("请选择数据生成节点");
        check = true;
      } else if (!this.form.tempType) {
        this.$message.error("请选择证书类型");
        check = true;
      } else if (!this.form.codeType) {
        this.$message.error("请选择编号类型");
        check = true;
      } else if (!this.form.codePrefix) {
        this.$message.error("请输入编号前缀");
        check = true;
      } else if (!this.form.generateSource) {
        this.$message.error("请选择获取数据方式");
        check = true;
      }
      return check;
    },
    // 证书配置提交
    onSubmit() {
      if (this.funCheck()) {
        return;
      }
      this.submitLoad = true;
      let url = this.tempId
        ? "/hxclass-management/certiftemp/updateTempConfig"
        : "/hxclass-management/certiftemp/addTempConfig";
      let method = this.tempId ? "PUT" : "POST";
      this.$ajax({
        url: url,
        method: method,
        params: this.form,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
        this.submitLoad = false;
      });
    },
    onChangeCodeType(e) {
      let type = e.target.value;
      let str = "";
      if (type == 1) {
        str = "KX";
      } else if (type == 2) {
        str = "HX";
      } else if (type == 5) {
        str = "XF";
      } else if (type == 6) {
        str = "ZY";
      }
      this.CodePlaceholder = "请输入证书前缀：例如（" + str + "001）";
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.tempId = this.$route.query.tempId ? this.$route.query.tempId : 0;
    this.getCourseList();
    this.getPictureDropDownList();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    if (this.tempId) {
      this.getDetail();
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_content_box {
  .tips {
    margin-top: 5px;
    width: 100%;
    font-size: 14px;
    color: #cccccc;
  }
  .L_R_inner {
    margin: 24px;

    .all_left_name {
      width: 190px;
      text-align: right;
    }

    .right_Div {
      .textarea.ant-input {
        width: 800px;
        height: 100px;
      }

      .add {
        margin-right: 10px;
      }

      .all_input {
        width: 337px;
      }

      /deep/.ant-input {
        height: 37px;
      }
      /deep/ .ant-input-number-input {
        line-height: 37px;
        height: 37px;
        padding: 0;
      }

      .else_input {
        margin-right: 12px;
        width: 140px;
      }

      .left {
        margin-left: 12px;
      }
    }
  }

  .pointerEvents {
    pointer-events: none;
  }
}
.certificate_box {
  .radio {
    display: flex;
    flex-direction: row;
    max-width: 500px;
    overflow-x: auto;
    overflow-y: hidden;
    .radioItem {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 10px;
      img {
        max-width: 100px;
        max-height: 120px;
        margin-bottom: 10px;
      }
    }
  }
  .radio::-webkit-scrollbar {
    height: 6px;
    width: 15px;
  }
  .radio::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .radio::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
}
.submit-btn {
  padding-left: 100px;
  .btn {
    margin-right: 30px;
  }
}
.foot-btn {
  margin-left: 130px;
  .btn {
    margin-right: 30px;
  }
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px #00000004;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: #e91212;
  border-color: #d9d9d9;
  background: #fff;
}
</style>
